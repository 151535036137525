
import { Vue, Component } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import UploadFile from '@/components/uploadFile/Index.vue'
import { FileInfo } from '@/types/common'

@Component({
  components: { UploadFile }
})
export default class IrrigationModelSetting extends Vue {
  dialogVisible = false
  items = [1, 2, 3, 4, 5]

  private info ={
    projectId: '',
    sprinklingImage: {
      fileName: '',
      filePrefix: '',
      fileUrl: ''
    }
  }

  private rules ={
    'sprinklingImage.filePrefix': [{ required: true, message: '请上传项目图片', trigger: ['change'] }]
  }

  private fileName = ''

  private submitShow = false

  private fileType = 'image/jpeg,image/jpg,image/png'

  private imgUrl = ''

  private projectList = []

  created () {
    this.loadData()
  }

  loadData () {
    this.$axios.get(this.$apis.irrigation.irrigationProjectList).then((res) => {
      this.projectList = res.list || []
    })
  }

  open (id: string) {
    this.dialogVisible = true
    this.info.projectId = id
  }

  onSuccess (file: FileInfo) {
    this.info.sprinklingImage = file
    this.imgUrl = file.filePrefix + file.fileUrl
  }

  deleteImg () {
    this.imgUrl = ''
    this.info.sprinklingImage = {
      fileName: '',
      filePrefix: '',
      fileUrl: ''
    }
  }

  onSubmit () {
    (this.$refs.info as ElForm).validate((valid) => {
      if (valid) {
        this.$axios.post(this.$apis.project.updateProject, this.info).then(() => {
          this.$message({ message: '保存成功', type: 'success' })
          this.imgUrl = ''
          this.info = {
            projectId: '',
            sprinklingImage: {
              fileName: '',
              filePrefix: '',
              fileUrl: ''
            }
          }
          this.fileName = ''
          this.dialogVisible = false
          this.loadData()
        })
      }
    })
  }

  access (id: string) {
    this.$router.push({
      path: '/irrigationmodelsetting/detail/setting',
      query: {
        projectId: id
      }
    })
  }
}
